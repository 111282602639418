// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-examples-landing-jsx": () => import("./../src/pages/examples/Landing.jsx" /* webpackChunkName: "component---src-pages-examples-landing-jsx" */),
  "component---src-pages-examples-login-jsx": () => import("./../src/pages/examples/Login.jsx" /* webpackChunkName: "component---src-pages-examples-login-jsx" */),
  "component---src-pages-examples-profile-jsx": () => import("./../src/pages/examples/Profile.jsx" /* webpackChunkName: "component---src-pages-examples-profile-jsx" */),
  "component---src-pages-examples-register-jsx": () => import("./../src/pages/examples/Register.jsx" /* webpackChunkName: "component---src-pages-examples-register-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-landing-2-jsx": () => import("./../src/pages/landing2.jsx" /* webpackChunkName: "component---src-pages-landing-2-jsx" */),
  "component---src-pages-y-kregular-jsx": () => import("./../src/pages/YKregular.jsx" /* webpackChunkName: "component---src-pages-y-kregular-jsx" */)
}

